@import "src/themes/mojito/styles/index.scss";
#frames-tabs {
  display: none;

  @include sm {
    display: block;
  }
}

.frames-tabs-container {
  display: flex;
  position: relative;
}

.frames-tabs-container .frames-tab {
  margin-right: 12px;
  margin-bottom: 8px;
  display: inline-block;
  padding: 8px 16px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
}

.frames-tabs-container .frames-tab.active,
.frames-tabs-container .frames-tab.active:hover {
  border-radius: 0.25rem;
  background-color: getvar($colors, "primary", "400") !important;
  color: #fff;
  outline: none;
  -webkit-text-decoration: underline 0.1em transparent;
  text-decoration: underline 0.1em transparent;
  text-decoration-color: transparent !important;
  -webkit-text-decoration-color: transparent !important;
  width: auto;
  max-width: 180px;
  span.tab-subLabel {
    color: white !important;
  }
}

.frames-tabs-container .frames-tab.frames-tab--rounded,
.frames-tabs-container .frames-tab.frames-tab--rounded.active,
.frames-tabs-container .frames-tab.frames-tab--rounded.active:hover {
  @extend .u-rounded-button;
}

.frame-div-toggle.active {
  .tab-picker-frame {
    display: flex;
    justify-content: flex-start;
  }
}

.frames-tabs-content-container {
  margin-top: 24px;
}

span.tab-subLabel {
  color: getvar($colors, "neutral", "400") !important;
}

.frames-tabs-container {
  max-width: 700px;
  display: flex;
  flex-wrap: wrap;
}

.frame-div-toggle .tab-picker-frame {
  @include sm {
    justify-content: flex-start !important;
  }
}
