@import "src/themes/mojito/styles/index.scss";
.Form {
  // max-width: #{getvar($breakpoints, "xs")};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .button,
  .button {
    margin-left: auto;
    margin-right: auto;
    display: table;
    margin-top: space(1.5);
  }
}

.contact-us {
  box-shadow: 2px 0px 6px 0px rgba(166, 166, 166, 0.25);

  @include sm {
    box-sizing: unset;
    max-width: 528px;
    margin: 0 auto;
  }

  @include lg {
    max-width: 710px;
    margin: 0 auto;
  }

  form {
    .Input {
      margin-bottom: space(1);
    }
    button {
      min-width: 212px;
      @include sm {
        min-width: 270px;
      }
      @include lg {
        min-width: 212px;
      }
    }
  }
}
