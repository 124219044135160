@import "src/themes/mojito/styles/index.scss";
.list-input {
  &__item {
    background-color: getvar($colors, "primary", "50");
    padding: space();
    margin-bottom: space();
    display: flex;
    position: relative;

    .Input {
      width: 100%;
      
      &--remove-button {
        width: calc(100% - space(4.5));
      }
    }

    .ListPicker__icon {
      width: space(1.25);
      height: space(1.25);
      position: absolute;
      top: space(4.063);
      right: space(2);
      margin-left: 0 !important;
      margin-top: 0 !important;

      span {
        border: space(0.188) solid getvar($colors, "primary", "500");
      }

      div, span {
        width: space(1.25);
        height: space(1.25);
        min-width: space(1.25);

        &::after {
          font-size: space(2.2);
          top: space(-0.125);
          right: space(0.323);
          color: getvar($colors, "primary", "500");
        }
      }

    }

    .Input__label-container {
      margin-bottom: space();
    }
  }
}