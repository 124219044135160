@import "src/themes/mojito/styles/index.scss";
.switch__label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.switch__span {
  margin-left: space(0.5);
  color: #000;
  font-size: space(1);
  line-height: space(2.25);
  transition: 0.3s;
}

.switch__toggle {
  background-color: getvar($colors, "neutral", "400");
  position: relative;
  min-width: space(2.813);
  max-width: space(2.813);
  height: space(1.5);
  margin: 0;
  vertical-align: top;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
  box-sizing: border-box;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.switch__toggle::after {
  content: "";
  position: absolute;
  left: 2px;
  width: space(1);
  height: space(1);
  background-color: white;
  border-radius: 50%;
  transform: translateX(0);
  transition: all 0.5s cubic-bezier(0.2, 0.85, 0.32, 1.2);
}
.switch__toggle:checked {
  background-color: getvar($colors, "primary", "500");
}

.switch__toggle:checked::after {
  transform: translateX(calc(100% + 10px));
  left: initial;
  background-color: white;
}

.switch__toggle:checked + .switch__span {
  color: #000;
}

.swicth__content {
  margin-top: space(0.5);
  color: getvar($colors, "neutral", "700") !important;
}
